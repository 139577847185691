<template>
    <v-card class="" tile>
        <v-card-text>
            <v-text-field v-model="search" :prepend-inner-icon="icons.mdiMagnify" class="search-field" hide-details
                rounded dense outlined clearable>
            </v-text-field>
        </v-card-text>

        <v-data-table :headers="headers" :items="isProduct === true? produits: services" :loading="isDataIsLoading"
            loading-text="Loading... Please wait" :search="search" sort-by="title" class="elevation-1 row-pointer"
            style="cursor: pointer" @click:row="(item) => viewDataTableElement(item)">
            <template v-slot:item.name="{ item }">
                <v-col class="d-flex flex-row">
                    <span>{{ item.name }}</span>
                </v-col>
            </template>
            <template v-slot:item.title="{ item }">
                <v-col class="d-flex flex-row">
                    <span>{{ item.title }} </span>
                </v-col>
            </template>

            <template v-slot:item.domain="{ item }">
                <v-col class="d-flex flex-row">
                    <span>{{ item.domain }}</span>
                </v-col>
            </template>
            <template v-slot:item.status="{ item }">
                <div class="d-flex flex-row align-center primary--text" v-if="item.status === '1'">
                    <v-icon size="13" class="mr-1 primary--text">{{
                        icons.mdiCheckboxMarkedCircle
                    }}</v-icon>
                    <span class="text-caption primary--text">Published</span>
                </div>
                <div class="d-flex flex-row align-center primary--text" v-if="item.status === '0'">
                    <v-icon size="13" class="mr-1 primary--text">{{
                        icons.mdiAlertCircle
                    }}</v-icon>
                    <span class="text-caption primary--text">Draft</span>
                </div>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-col class="d-flex flex-row">
                    <v-icon class="mr-2" small @click.stop="viewDataTableElement(item)">
                        {{ icons.mdiEye }}
                    </v-icon>
                </v-col>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiEye, mdiShareAll, mdiAlertCircle, mdiCloseCircle, mdiCheckboxMarkedCircle } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'

var md5 = require("md5")

export default {
    props: {
        isProduct: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            servicelistdata: [],
            services: [],
            produits: [],
            isDataIsLoading: true,
        }
    },

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
        },
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    beforeMount() {
        this.isDataIsLoading = true
        Drequest.api(`lazyloading.service?dfilters=on&enterprise_id:eq=${this.$store.getters.getCompany.id}`)
            .get((response) => {
                switch (response.success) {
                    case true:
                        response.listEntity.forEach((value, index) => {
                            this.servicelistdata.push({
                                id: value.id,
                                name: value.name,
                                title: value.title,
                                domain: value.domain,
                                enterprise: value.enterprise,
                                status: value.status,
                                pos: index + 1
                            })
                        })
                        this.isDataIsLoading = false
                        const [a, b] = this.servicelistdata.reduce((acc, element) => {
                            if (element.name === 'Service') {
                                this.services.push(element); // Ajoute aux correspondants
                            } else {
                                this.produits.push(element); // Ajoute aux non correspondants
                            }
                            return acc;
                        }, [[], []]);
                        break;
                    default:
                        this.$fire({
                            title: "Erreur inattendue!",
                            type: "error",
                            text: "Veuillez recharger la page..!",
                            timer: 3000
                        })
                        this.isDataIsLoading = false
                        break;
                }
            })
            .catch((err) => {
                console.log("err")
            })
    },

    methods: {
        highlightClickedRow(value) {
            const tr = value.target.parentNode;
            tr.classList.add('primary');
        },


        viewDataTableElement(item) {
            // this.editedIndex = this.servicelistdata.indexOf(item)
            // this.editedItem = Object.assign({}, item)
            // this.dialog = true
            // console.log(item.id)
            // this.$store.commit("setService", {
            //     id: item.id
            // });
            // this.$router.push({ name: 'company.service.view', params: { company: this.$store.getters.getCompany.name, f_id: md5(item.id + 'l') } })
            this.$router.push({ name: 'company.service.view', params: { company: this.$store.getters.getCompany.name, f_id: item.id, title: item.title  } })
        },
        deleteItem(item) {
            this.editedIndex = this.servicelistdata.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.servicelistdata.splice(this.editedIndex, 1)
            this.closeDelete()
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.servicelistdata[this.editedIndex], this.editedItem)
            } else {
                this.servicelistdata.push(this.editedItem)
            }
            this.close()
        },
    },
    setup(props) {
        const icons = { mdiMagnify, mdiEye, mdiShareAll, mdiAlertCircle, mdiCloseCircle, mdiCheckboxMarkedCircle }
        let search = ref('')

        const headers = [
            { text: 'Name', value: 'name' },
            {
                text: 'Title',
                align: 'start',
                sortable: true,
                value: 'title',
            },
            { text: 'Domain', value: 'domain' },
            { text: 'Status', value: 'status' },
            { text: 'Actions', value: 'actions', sortable: false },
        ]
        return {
            headers, search, icons
        }
    },
}
</script>

<style lang="scss" scoped>
//.row-pointer>.v-data-table__wrapper>table>tbody>tr:hover {
//   cursor: pointer;
//}

.row-pointer>>>tbody tr :hover {
    cursor: pointer;
}
</style>