var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":""}},[_c('v-card-text',[_c('v-text-field',{staticClass:"search-field",attrs:{"prepend-inner-icon":_vm.icons.mdiMagnify,"hide-details":"","rounded":"","dense":"","outlined":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1 row-pointer",staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.isProduct === true? _vm.produits: _vm.services,"loading":_vm.isDataIsLoading,"loading-text":"Loading... Please wait","search":_vm.search,"sort-by":"title"},on:{"click:row":function (item) { return _vm.viewDataTableElement(item); }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-col',{staticClass:"d-flex flex-row"},[_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('v-col',{staticClass:"d-flex flex-row"},[_c('span',[_vm._v(_vm._s(item.title)+" ")])])]}},{key:"item.domain",fn:function(ref){
var item = ref.item;
return [_c('v-col',{staticClass:"d-flex flex-row"},[_c('span',[_vm._v(_vm._s(item.domain))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === '1')?_c('div',{staticClass:"d-flex flex-row align-center primary--text"},[_c('v-icon',{staticClass:"mr-1 primary--text",attrs:{"size":"13"}},[_vm._v(_vm._s(_vm.icons.mdiCheckboxMarkedCircle))]),_c('span',{staticClass:"text-caption primary--text"},[_vm._v("Published")])],1):_vm._e(),(item.status === '0')?_c('div',{staticClass:"d-flex flex-row align-center primary--text"},[_c('v-icon',{staticClass:"mr-1 primary--text",attrs:{"size":"13"}},[_vm._v(_vm._s(_vm.icons.mdiAlertCircle))]),_c('span',{staticClass:"text-caption primary--text"},[_vm._v("Draft")])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-col',{staticClass:"d-flex flex-row"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.viewDataTableElement(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }